.big-title-container {
  width:  100%;
  display: flex;
  padding: 64px 0;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .first-line {
    padding: 0 32px;
    font-size: 20px;
  }

  .title {
    padding: 32px;
    line-height: 1.5;
    font-size: 32px;
    font-weight: bolder;
  }

  background-color: antiquewhite;
}