.time-loc-info {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 12px 0;
  .line {
    width: fit-content;
    font-size: 20px;
    margin: 6px 0;
  }
}



.text-section {
  display: flex;
  flex-direction: column;
  max-width: calc(100% - 96px);
  padding: 48px 48px 0 48px;
  align-items: center;
  line-height: 1.5;
  font-size: 18px;
  // hyphens: auto;
  text-align: justify;

  .title {
    font-weight: bolder;
    font-size: 24px;
    margin: 0;
  }

  .shrink-column {
    width: 100%;
  }

  .item {
    display: flex;
    align-items: center;
    margin: 2px 0;
  }

  .item-name {
    font-weight: bold;
    padding-right: 6px;
    margin: 0;
  }
}

.member-display { 
  padding-top: 32px;
}

@media (max-width: 730px) {
  .member-display {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (min-width: 730px) and (max-width: 1100px){
  .member-display {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1100px){
  .member-display {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 1100px) {
  .adaptive-two-column {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (min-width: 1100px) {
  .adaptive-two-column {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 600px) { 
  .text-section {
    max-width: calc(100% - 32px);
    padding: 48px 24px 0 24px;
    text-align: left;
    hyphens: auto;
    font-size: 16px;
  }

  .time-loc-info { 
    .line {
      font-size: 16px;
    }
  }
}

