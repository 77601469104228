.character-display-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  .image {
    width: 240px;
    object-fit: cover;
    border-radius: 6px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }
}